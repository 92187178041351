module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["it","en"],"defaultLanguage":"it","siteUrl":"https://www.yourdomain.tld","i18nextOptions":{"fallbackLng":"it","supportedLngs":["it","en"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
