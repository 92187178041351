import "bootstrap/dist/css/bootstrap.min.css";
import "./src/font.css";

export const onRouteUpdate = () => {
  setTimeout(() => {
    const loader = document.getElementById("___loader");

    if (loader) {
      loader.style.display = "none";
    }
  }, 1000);
};
